@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.email {
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.company {
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.firstNameRoot {
  width: calc(50% - 9px);
}

.lastNameRoot {
  width: calc(50% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.title {
  background: var(--colorSuccess);
  color: white;
  padding: 10px;
  text-align: center;
}

.customField {
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}